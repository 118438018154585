<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div>
          <h5>
            측정시간 :
            {{
              filter건조실온도Logs.length > 0
                ? yyyyMMddHHmmss(new Date(filter건조실온도Logs[0].create_time))
                : ''
            }}
          </h5>
          <div class="input_text">
            <label>조회일</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="select_date"
              @change="
                $event =>
                  $store.commit(
                    'setSelectDateToPlcSensorLog',
                    $event.target.value,
                  )
              "
            />
          </div>
        </div>
        <div>
          <h5>건조실 온도</h5>
          <div>
            <label>정상온도</label>
            <div class="input_text">
              <input
                type="number"
                placeholder=""
                :value="find건조실온도 != null ? find건조실온도.min_value : 0"
                @input="
                  $event => (find건조실온도.min_value = $event.target.value)
                "
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="number"
                placeholder=""
                :value="find건조실온도 != null ? find건조실온도.max_value : 0"
                @input="
                  $event => (find건조실온도.max_value = $event.target.value)
                "
              />
            </div>
            <label>도</label>
          </div>
          <div>
            <label>정상습도</label>
            <div class="input_text">
              <input
                type="number"
                placeholder=""
                :value="find건조실습도 != null ? find건조실습도.min_value : 0"
                @input="
                  $event => (find건조실습도.min_value = $event.target.value)
                "
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="number"
                placeholder=""
                :value="find건조실습도 != null ? find건조실습도.max_value : 0"
                @input="
                  $event => (find건조실습도.max_value = $event.target.value)
                "
              />
            </div>
            <label>%</label>
          </div>
        </div>
      </div>
      <plc-status-list-form-temp-humi
        :listArray="filter건조실온도Logs"
        :listArray2="filter건조실습도Logs"
        :pageSize="20"
        :minTemp="find건조실온도 != null ? Number(find건조실온도.min_value) : 0"
        :maxTemp="find건조실온도 != null ? Number(find건조실온도.max_value) : 0"
        :minHumi="find건조실습도 != null ? Number(find건조실습도.min_value) : 0"
        :maxHumi="find건조실습도 != null ? Number(find건조실습도.max_value) : 0"
      ></plc-status-list-form-temp-humi>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import { yyyymmdd } from '@/utils/func';
import PlcStatusListFormTempHumi from './PlcStatusListFormTempHumi';

export default {
  components: {
    PlcStatusListFormTempHumi,
  },
  mixins: [FETCH_MIXIN],
  computed: {
    ...mapGetters({
      plc_sensors: 'getPlcSensor',
      plc_sensor_logs: 'getPlcSensorLog',
      plc_sensor_log_times: 'getPlcSensorLogTime',
      select_date: 'getSelectDateFromPlcSensorLog',
    }),
    find건조실온도() {
      if (this.plc_sensors.length < 1) {
        return null;
      } else {
        const 건조실온도 = this.plc_sensors.find(x => x.id == 5);
        return 건조실온도 == undefined ? null : 건조실온도;
      }
    },
    find건조실습도() {
      if (this.plc_sensors.length < 1) {
        return null;
      } else {
        const 건조실습도 = this.plc_sensors.find(x => x.id == 6);
        return 건조실습도 == undefined ? null : 건조실습도;
      }
    },

    filter건조실온도Logs() {
      const filterdLog = this.lodash
        .clonedeep(this.plc_sensor_log_times)
        .filter(x => x.plc_sensor_id == this.find건조실온도.id);
      return filterdLog;
    },
    filter건조실습도Logs() {
      const filterdLog = this.lodash
        .clonedeep(this.plc_sensor_log_times)
        .filter(x => x.plc_sensor_id == this.find건조실습도.id);
      return filterdLog;
    },
  },
  methods: {
    yyyyMMddHHmmss(date) {
      var MM = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();
      var hh = date.getHours();
      var mm = date.getMinutes();
      var ss = date.getSeconds();

      return [
        date.getFullYear(),
        '-' + (MM > 9 ? MM : '0' + MM),
        '-' + (dd > 9 ? dd : '0' + dd),
        ' (' + (hh > 9 ? hh : '0' + hh),
        ':' + (mm > 9 ? mm : '0' + mm),
        ':' + (ss > 9 ? ss : '0' + ss),
        ')',
      ].join('');
    },
    async fetchSensorLogs() {
      this.showSpinner();
      await this.$store
        .dispatch(
          'FETCH_PLC_SENSOR_LOG_TIME_NOT_PAGE',
          {
            date: this.select_date,
            start: '00:00',
            end: '24:00',
            loction: this.find건조실온도.plc_location_id,
            type: this.find건조실온도.plc_sensor_type_id,
            detailType: 0,
          },
          '온습도현황',
        )
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          this.hideSpinner();
        });
    },
  },
  async created() {
    if (this.plc_sensors.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR', 'PLC 센서');
    }

    if (this.select_date == null) {
      const date = new Date();
      this.$store.commit('setSelectDateToPlcSensorLog', yyyymmdd(date));
    }
    this.$store.commit('resetPlcSensorLogTime');
    await this.fetchSensorLogs();
  },
};
</script>

<style lang="scss" scoped></style>
