<template>
  <div
    id="contents"
    :class="{
      twin_temp_status: tabIndex == 0,
      dry_th_status: tabIndex == 1,
      plc_metal_status: tabIndex == 2,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToPlcMonitorPage'"
          :options="[
            { title: '증삼실 온도 현황', name: 'twin_temp_status' },
            { title: '건조실 온습도 현황', name: 'dry_th_status' },
            { title: '금속 검출 현황', name: 'plc_metal_status' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button
            class="btn_admin"
            v-if="tabIndex == 0"
            @click="submit증삼실기준()"
          >
            저장
          </button>
          <button
            class="btn_admin"
            v-else-if="tabIndex == 1"
            @click="submit건조실기준()"
          >
            저장
          </button>
          <button
            v-if="tabIndex == 0"
            class="btn_admin"
            @click="
              () => {
                $store.commit('resetPlcSensorLogTime');
                FETCH_WITH_PAYLOAD(
                  'FETCH_PLC_SENSOR_LOG_TIME_NOT_PAGE',
                  {
                    date: select_date,
                    start: '00:00',
                    end: '24:00',
                    loction: 2,
                    type: 2,
                    detailType: 3,
                  },
                  '온습도현황',
                );
              }
            "
          >
            조회
          </button>
          <button
            v-else-if="tabIndex == 1"
            class="btn_admin"
            @click="
              () => {
                $store.commit('resetPlcSensorLogTime');
                FETCH_WITH_PAYLOAD(
                  'FETCH_PLC_SENSOR_LOG_TIME_NOT_PAGE',
                  {
                    date: select_date,
                    start: '00:00',
                    end: '24:00',
                    loction: 3,
                    type: 1,
                    detailType: 0,
                  },
                  '온습도현황',
                );
              }
            "
          >
            조회
          </button>
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">증삼실 온도 현황</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">건조실 온습도 현황</a>
          </li>
          <li :class="{ active: tabIndex == 2 }">
            <a @click="SetOpenTabIndex(2)">금속 검출 현황</a>
          </li>
        </ul>
      </div>
      <twin-temp-status-form v-if="tabIndex == 0"></twin-temp-status-form>
      <dry-th-status-form v-if="tabIndex == 1"></dry-th-status-form>
      <plc-metal-status-form v-if="tabIndex == 2"></plc-metal-status-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import TwinTempStatusForm from '@/views/forms/Custom/Monitor/6/TwinTempStatusForm';
import DryThStatusForm from '@/views/forms/Custom/Monitor/6/DryThStatusForm';
import PlcMetalStatusForm from '@/views/forms/Custom/Monitor/6/PlcMetalStatusForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import FETCH_MIXIN from '@/mixins/fetch';
export default {
  mixins: [ClosePageMixin, FavoriteMixin, FETCH_MIXIN],
  components: {
    TwinTempStatusForm,
    DryThStatusForm,
    PlcMetalStatusForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
      select_date: 'getSelectDateFromPlcSensorLog',
      plc_sensors: 'getPlcSensor',
    }),
    find당침기() {
      if (this.plc_sensors.length < 1) {
        return null;
      } else {
        const 당침기 = this.plc_sensors.find(x => x.id == 4);
        return 당침기 == undefined ? null : 당침기;
      }
    },
    find증삼기() {
      if (this.plc_sensors.length < 1) {
        return null;
      } else {
        const 증삼기 = this.plc_sensors.find(x => x.id == 3);
        return 증삼기 == undefined ? null : 증삼기;
      }
    },
    find건조실온도() {
      if (this.plc_sensors.length < 1) {
        return null;
      } else {
        const 건조실온도 = this.plc_sensors.find(x => x.id == 5);
        return 건조실온도 == undefined ? null : 건조실온도;
      }
    },
    find건조실습도() {
      if (this.plc_sensors.length < 1) {
        return null;
      } else {
        const 건조실습도 = this.plc_sensors.find(x => x.id == 6);
        return 건조실습도 == undefined ? null : 건조실습도;
      }
    },
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToPlcMonitorPage',
    }),
    async submit증삼실기준() {
      let clone증삼 = this.lodash.clonedeep(this.find증삼기);
      clone증삼.min_value = this.$makeNumber(clone증삼.min_value);
      clone증삼.max_value = this.$makeNumber(clone증삼.max_value);

      let clone당침 = this.lodash.clonedeep(this.find당침기);
      clone당침.min_value = this.$makeNumber(clone당침.min_value);
      clone당침.max_value = this.$makeNumber(clone당침.max_value);

      let payload = {
        data_list: [clone증삼, clone당침],
      };

      this.showSpinner();
      await this.$store
        .dispatch('UPDATE_FETCH_PLC_SENSOR_CURRENT_LOG_TO_PLC_SENSR', payload)
        .then(() => {
          this.openOneButtonModal(
            '수정 성공',
            '증삼실 온도 기준값을 수정하였습니다.',
          );
          this.FETCH('FETCH_PLC_SENSOR', '센서 정보');
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '저장 중 에러',
            '기준값 설정 저장 중 에러발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async submit건조실기준() {
      let clone온도 = this.lodash.clonedeep(this.find건조실온도);
      clone온도.min_value = this.$makeNumber(clone온도.min_value);
      clone온도.max_value = this.$makeNumber(clone온도.max_value);

      let clone습도 = this.lodash.clonedeep(this.find건조실습도);
      clone습도.min_value = this.$makeNumber(clone습도.min_value);
      clone습도.max_value = this.$makeNumber(clone습도.max_value);

      let payload = {
        data_list: [clone온도, clone습도],
      };

      this.showSpinner();
      await this.$store
        .dispatch('UPDATE_FETCH_PLC_SENSOR_CURRENT_LOG_TO_PLC_SENSR', payload)
        .then(() => {
          this.openOneButtonModal(
            '수정 성공',
            '건조실 온습도 기준값을 수정하였습니다.',
          );
          this.FETCH('FETCH_PLC_SENSOR', '센서 정보');
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '저장 중 에러',
            '기준값 설정 저장 중 에러발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPlcMonitorPage');
      this.$route.meta.menu_init = false;
    }
    if (this.plc_sensors.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR', 'PLC 센서');
    }
  },
};
</script>

<style lang="scss">
@import 'gumsan_monitoring';
</style>
