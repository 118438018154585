<template>
  <div class="mes_page_wrap">
    <div class="mes_tbl_wrap">
      <table class="mes_tbl">
        <thead>
          <tr>
            <th>일시</th>
            <th>온도</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in plc_lists" :key="item.id">
            <td>{{ yyyyMMddHHmmss(new Date(item.create_time)) }}</td>
            <td
              :class="{
                abnormal: item.value < minValue || item.value > maxValue,
              }"
            >
              {{ item.value }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="btn_wrap pagigate_wrap">
      <button class="page-count" @click="pageNum = 0" :disabled="pageNum === 0">
        {{ `[맨 앞]` }}
      </button>
      <button
        class="page-count"
        @click="!(pageNum - 5 > 0) ? (pageNum = 0) : (pageNum = pageNum - 5)"
        :disabled="pageNum - 5 < 0"
      >
        {{ `[5쪽 앞]` }}
      </button>
      <button
        type="button"
        :disabled="pageNum === 0"
        @click="prevPage"
        class="prev_btn"
      >
        {{ `[이전]` }}
      </button>
      <button
        class="page-count"
        v-if="pageCount >= 5 && pageNum == pageCount - 1"
        @click="pageNum = pageCount - 3"
      >
        {{ `[${[pageNum - 3]}]` }}
      </button>
      <button
        class="page-count"
        v-if="pageCount >= 4 && pageNum >= pageCount - 2"
        @click="pageNum = pageCount - 2"
      >
        {{ `[${[pageNum - 2]}]` }}
      </button>
      <button
        class="page-count"
        v-if="pageNum - 1 > 0"
        @click="pageNum = pageNum - 2"
      >
        {{ `[${pageNum - 1}]` }}
      </button>
      <button
        class="page-count"
        v-if="pageNum > 0"
        @click="pageNum = pageNum - 1"
      >
        {{ `[${pageNum}]` }}
      </button>
      <button class="page-count">
        <b> {{ `[${pageNum + 1}]` }} </b>
      </button>
      <button
        class="page-count"
        v-if="pageNum + 1 < pageCount"
        @click="pageNum = pageNum + 1"
      >
        {{ `[${pageNum + 2}]` }}
      </button>
      <button
        class="page-count"
        v-if="pageNum + 2 < pageCount"
        @click="pageNum = pageNum + 2"
      >
        {{ `[${pageNum + 3}]` }}
      </button>

      <button
        class="page-count"
        v-if="pageCount >= 4 && pageNum < 1"
        @click="pageNum = 3"
      >
        {{ `[4]` }}
      </button>
      <button
        class="page-count"
        v-if="pageCount >= 5 && pageNum < 2"
        @click="pageNum = 4"
      >
        {{ `[5]` }}
      </button>

      <button
        type="button"
        :disabled="pageNum >= pageCount - 1"
        @click="nextPage"
        class="next_btn"
      >
        {{ `[다음]` }}
      </button>
      <button
        class="page-count"
        @click="
          !(pageNum + 5 < pageCount)
            ? (pageNum = pageCount - 1)
            : (pageNum = pageNum + 5)
        "
        :disabled="pageNum + 5 >= pageCount"
      >
        {{ `[5쪽 뒤]` }}
      </button>
      <button
        class="page-count"
        @click="pageNum = pageCount - 1"
        :disabled="pageNum >= pageCount - 1"
      >
        {{ `[맨 뒤]` }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageNum: 0,
    };
  },
  props: {
    listArray: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      required: false,
      default: 8,
    },
    minValue: {
      type: Number,
      required: true,
    },
    maxValue: {
      type: Number,
      required: true,
    },
  },
  methods: {
    yyyyMMddHHmmss(date) {
      var MM = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();
      var hh = date.getHours();
      var mm = date.getMinutes();
      var ss = date.getSeconds();

      return [
        date.getFullYear(),
        '-' + (MM > 9 ? MM : '0' + MM),
        '-' + (dd > 9 ? dd : '0' + dd),
        ' (' + (hh > 9 ? hh : '0' + hh),
        ':' + (mm > 9 ? mm : '0' + mm),
        ':' + (ss > 9 ? ss : '0' + ss),
        ')',
      ].join('');
    },
    nextPage() {
      this.pageNum += 1;
    },
    prevPage() {
      this.pageNum -= 1;
    },
  },
  watch: {
    listArray() {
      this.pageNum = 0;
    },
  },

  computed: {
    pageCount() {
      let listLeng = this.listArray.length,
        listSize = this.pageSize,
        page = Math.floor(listLeng / listSize);

      if (listLeng % listSize > 0) page += 1;

      return page;
    },
    plc_lists() {
      const start = this.pageNum * this.pageSize,
        end = start + this.pageSize;

      return this.listArray.slice(start, end);
    },
  },
};
</script>

<style scoped></style>
