<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div>
          <div class="input_text">
            <label>조회일</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="select_date"
              @change="
                $event => {
                  $store.commit(
                    'setSelectDateToPlcCounterLog',
                    $event.target.value,
                  );
                  FETCH_WITH_PAYLOAD('FETCH_COUNTER_LOG_DAY', select_date);
                }
              "
            />
          </div>
          <h5>금속검출량</h5>
        </div>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>일시</th>
              <th>검출 수량</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredCounterLog" :key="item.id">
              <td>{{ item.end_time | formatDateHHMMSS }}</td>
              <td>{{ $makeComma(item.value) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [FETCH_MIXIN],
  computed: {
    ...mapGetters({
      plc_counter_log: 'getCounterLogDay',
      select_date: 'getSelectDateFromPlcCounterLog',
    }),
    filteredCounterLog() {
      if (this.plc_counter_log.length > 0) {
        return this.plc_counter_log.filter(
          x => x.plc_sensor_detail_type_id == 5,
        );
      } else return [];
    },
  },
  async created() {
    if (this.select_date == null) {
      const date = new Date();
      this.$store.commit('setSelectDateToPlcCounterLog', yyyymmdd(date));
    }
    console.log(this.select_date);
    if (this.plc_counter_log.length < 1) {
      this.FETCH_WITH_PAYLOAD('FETCH_COUNTER_LOG_DAY', this.select_date);
    }
  },
};
</script>

<style lang="scss" scoped></style>
