var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    twin_temp_status: _vm.tabIndex == 0,
    dry_th_status: _vm.tabIndex == 1,
    plc_metal_status: _vm.tabIndex == 2,
  },attrs:{"id":"contents"}},[_c('div',{staticClass:"contents_head"},[_c('h2',[_vm._v(_vm._s(_vm.$getPageTitle(_vm.$route.path)))]),(_vm.$screen.width < 1280)?_c('div',{staticClass:"aside"},[_c('aside-selectric',{attrs:{"id":'tabletAsideSelectric',"watch":_vm.tabIndex,"commit":'setOpenTabIndexToPlcMonitorPage',"options":[
          { title: '증삼실 온도 현황', name: 'twin_temp_status' },
          { title: '건조실 온습도 현황', name: 'dry_th_status' },
          { title: '금속 검출 현황', name: 'plc_metal_status' } ]}})],1):_vm._e(),_c('div',{staticClass:"management_btn_group"},[_c('div',[_c('span',{staticClass:"add_favorite",class:{ on: _vm.isFavorOn },on:{"click":_vm.AddFavorite}},[_vm._v("즐겨찾기 추가")]),(_vm.tabIndex == 0)?_c('button',{staticClass:"btn_admin",on:{"click":function($event){_vm.submit증삼실기준()}}},[_vm._v(" 저장 ")]):(_vm.tabIndex == 1)?_c('button',{staticClass:"btn_admin",on:{"click":function($event){_vm.submit건조실기준()}}},[_vm._v(" 저장 ")]):_vm._e(),(_vm.tabIndex == 0)?_c('button',{staticClass:"btn_admin",on:{"click":function () {
              _vm.$store.commit('resetPlcSensorLogTime');
              _vm.FETCH_WITH_PAYLOAD(
                'FETCH_PLC_SENSOR_LOG_TIME_NOT_PAGE',
                {
                  date: _vm.select_date,
                  start: '00:00',
                  end: '24:00',
                  loction: 2,
                  type: 2,
                  detailType: 3,
                },
                '온습도현황'
              );
            }}},[_vm._v(" 조회 ")]):(_vm.tabIndex == 1)?_c('button',{staticClass:"btn_admin",on:{"click":function () {
              _vm.$store.commit('resetPlcSensorLogTime');
              _vm.FETCH_WITH_PAYLOAD(
                'FETCH_PLC_SENSOR_LOG_TIME_NOT_PAGE',
                {
                  date: _vm.select_date,
                  start: '00:00',
                  end: '24:00',
                  loction: 3,
                  type: 1,
                  detailType: 0,
                },
                '온습도현황'
              );
            }}},[_vm._v(" 조회 ")]):_vm._e(),_c('button',{staticClass:"btn_admin",on:{"click":function($event){return _vm.CloseThisPage()}}},[_vm._v("닫기")])])])]),_c('div',{staticClass:"section"},[(_vm.$screen.width >= 1280)?_c('div',{staticClass:"aside"},[_c('ul',[_c('li',{class:{ active: _vm.tabIndex == 0 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(0)}}},[_vm._v("증삼실 온도 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 1 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(1)}}},[_vm._v("건조실 온습도 현황")])]),_c('li',{class:{ active: _vm.tabIndex == 2 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(2)}}},[_vm._v("금속 검출 현황")])])])]):_vm._e(),(_vm.tabIndex == 0)?_c('twin-temp-status-form'):_vm._e(),(_vm.tabIndex == 1)?_c('dry-th-status-form'):_vm._e(),(_vm.tabIndex == 2)?_c('plc-metal-status-form'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }